@font-face{
  font-family: 'Wigrum';
  src:  url('./wigrumweb-light.woff') format("woff"),
        url('./wigrumweb-light.eot') format("eot");
  font-weight: 300;
  font-style: normal;
}

@font-face{
  font-family: 'Wigrum';
  src:  url('./wigrumweb-medium.woff') format("woff"),
        url('./wigrumweb-medium.eot') format("eot");
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family: 'Wigrum';
  src:  url('./wigrumweb-bold.woff') format("woff"),
        url('./wigrumweb-bold.eot') format("eot");
  font-weight: 700;
  font-style: normal;
}